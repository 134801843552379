<div id="page-content" class="h-100" style="overflow-y: auto">
    <div class="d-flex align-items-start justify-content-center ">
        <div class="centered-content">
            <div class="d-flex mb-3 justify-content-center" style="height: 102px">
                <div class="d-flex align-items-center justify-content-center">
                    <h2>Veille - messages</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-start justify-content-left">
        <div class="p-2">
            <p>Récupérer sur ce support la configuration du fond d'écran pour les inscriptions libres.</p>
            <p>
                <button (click)="sync()" [disabled]="!(isConnected$ | async)" class="btn btn-primary">
                    Synchroniser
                </button>
            </p>
        </div>
    </div>
    <div *ngIf="bgUrl">
        <img [src]="bgUrl" width="300" height="300" class="m-2">
    </div>
</div>

<div id="top-nav" class="d-flex justify-content-between  align-items-center bd-highlight mb-0">
    <div class="p-3">
        <a [routerLink]="['/settings']" class="loro-btn-small loro-btn-grey btn-logout">
            <i class="loro-icon icon-fleche-gauche" aria-hidden="true"></i>
        </a>
    </div>
</div>

<ng-template #status_unpublished>&#x2717;</ng-template>