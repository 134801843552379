<div class="d-flex align-items-center justify-content-center h-100">
    <div class="loro-content-centered loro-content-530">
        <header>
            <img src="./../assets/images/logo-loro.svg" class="logo img-fluid">
            <img src="./../assets/images/banner-promo.png" class="img-fluid">
        </header>

        <div>
            <div [hidden]="isLocalStorageNameSupported()" class="alert alert-danger">
                <span class="sr-only">Error:</span> Your web browser does not support storing settings locally. In
                Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or
                some features may not work properly for you.
            </div>

            <div [hidden]="!error" class="alert alert-danger">
                <span class="sr-only">Error:</span>
                {{ error }}
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <input type="text" formControlName="username" class="form-control" placeholder="Login">
                </div>
                <div class="form-group">
                    <input type="password" formControlName="password" class="form-control" placeholder="Mot de passe">
                </div>

                <button [disabled]="!loginForm.valid"
                        class="loro-btn loro-btn-orange loro-btn-small loro-btn-shadow-right mx-auto" type="submit">
                    <i class="loro-icon icon-fleche-droite"></i>
                </button>
            </form>
        </div>
    </div>
</div>