<div id="page-content" class="h-100">
    <div class="d-flex align-items-start justify-content-center">
        <div class="centered-content">
            <div class="d-flex mb-3 justify-content-center" style="height: 102px">
                <div class="d-flex align-items-center justify-content-center">
                    <h2>Statistiques</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="centered-content">
        <div class="row">
            <div class="col-12 game-box">
                <p>Nombre d'inscriptions pour la journée</p>
                <p><strong>{{inscriptionsCount$ | async}}</strong></p>
            </div>
        </div>
    </div>
</div>

<div id="top-nav" class="d-flex justify-content-between  align-items-center bd-highlight mb-0">
    <div class="p-3">
        <a [routerLink]="['/settings']" class="loro-btn-small loro-btn-grey btn-logout">
            <i class="loro-icon icon-fleche-gauche" aria-hidden="true"></i>
        </a>
    </div>
</div>