<div id="page-content" class="h-100" style="overflow-y: auto">
    <div class="d-flex align-items-start justify-content-center ">
        <div class="centered-content">
            <div class="d-flex mb-3 justify-content-center" style="height: 102px">
                <div class="d-flex align-items-center justify-content-center">
                    <h2>Veille - messages</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-start justify-content-left">
        <div class="p-2">
            <p>Récupérer sur ce support une copie identique de la configuration des messages du serveur distant.
                Attention, cela <strong>supprimera toute configuration</strong> réalisée sur ce support.</p>
            <p>
                <button (click)="syncMessages()" [disabled]="!(isConnected$ | async)" class="btn btn-primary">
                    Synchroniser les messages
                </button>
            </p>
        </div>
    </div>
    <!--
    <div class="d-flex align-items-start justify-content-left">
      <div class="p-2">
        <p>Récupérer tous les messages du serveur distant sur ce support.</p>
        <p><button (click)="getMessages()" [disabled]="!(isConnected$ | async)" class="btn btn-primary">Récupérer les messages</button></p>
      </div>
    </div>
    <div class="d-flex align-items-start justify-content-left">
      <div class="p-2">
        <p>Supprimer <strong>tous les messages</strong> de ce support.</p>
        <p><button (click)="deleteLocalMessages()" class="btn btn-primary">Supprimer les messages</button></p>
      </div>
    </div>
    -->
    <div *ngFor="let message of messages$ | async" class="message">
        <hr/>
        <div class="p-2">
            <h5>{{ message.title }} <span *ngIf="message.published;else status_unpublished">&#x2714;</span></h5>
        </div>
        <div class="d-flex justify-content-start">
            <div class="p-2">

                <div [innerHTML]="message.message"></div>
            </div>
            <div class="p-2">
                <div [innerHTML]="message.messageSecondary"></div>
            </div>
            <div class="ml-auto p-2 align-self-center">
                <a [routerLink]="['/settings/messages/', message.id]" class="btn btn-primary btn-sm">Modifier</a>
            </div>
        </div>
    </div>
</div>

<div id="top-nav" class="d-flex justify-content-between  align-items-center bd-highlight mb-0">
    <div class="p-3">
        <a [routerLink]="['/settings']" class="loro-btn-small loro-btn-grey btn-logout">
            <i class="loro-icon icon-fleche-gauche" aria-hidden="true"></i>
        </a>
    </div>
</div>

<ng-template #status_unpublished>&#x2717;</ng-template>