<div id="top-nav" class="d-flex justify-content-between  align-items-center bd-highlight mb-0"
     style="display:none !important; z-index: 1000">
    <div class="p-3">
        <a (click)="logout()" class="loro-btn-small loro-btn-grey btn-logout">
            <i class="loro-icon icon-logout" aria-hidden="true"></i>
        </a>
    </div>
    <div class="p-3">
    </div>
</div>

<ngx-slick class="carousel" #slickModal="slick-modal" [config]="slideConfig" (afterChange)="afterSlideChange($event)">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <img src="{{ slide.img }}" alt="" width="100%">
        <div class="message"
             [ngStyle]="{'position': 'absolute', 'left': slide.positionX+'px', 'top': slide.positionY+'px', 'font-size': slide.textSize+'px', 'line-height': slide.textSize+'px', 'color': slide.color, 'text-align': slide.messageAlignment }">
            <div [innerHTML]="slide.message"></div>
        </div>

        <div class="message-secondary"
             [ngStyle]="{'position': 'absolute', 'left': slide.positionXSecondary+'px', 'top': slide.positionYSecondary+'px', 'font-size': slide.textSizeSecondary+'px', 'line-height': slide.textSizeSecondary+'px', 'color': slide.color, 'text-align': slide.messageSecondaryAlignment }">
            <div [innerHTML]="slide.messageSecondary"></div>
        </div>
    </div>
</ngx-slick>
