<div id="circle-nav-wrapper">
    <button type="button" class="collapsed navbar-toggle trigger">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
    </button>
    <ul id="circle-nav">
        <li>
            <a [routerLink]="['/game/choose']">
                <i class="loro-icon icon-menu-jeu"></i>
            </a>
        </li>
        <li>
            <a href="#" disabled="disabled">
                <i class="loro-icon icon-demo"></i>
            </a>
        </li>
        <li>
            <a [routerLink]="['/game/register/cyclist']">
                <i class="loro-icon icon-nouveau-joueur"></i>
            </a>
        </li>
        <li>
            <a [routerLink]="['/dashboard']">
                <i class="loro-icon icon-reglage"></i>
            </a>
        </li>
    </ul>
</div>

<div class="container-fluid h-100">
    <div class="row h-100">
        <div class="col-3 col-md-4 h-100">
            <section id="products-wrapper" class="d-flex justify-content-center h-100">
                <div class="w-100 h-100 d-table">
                    <div id="products" class="d-table-cell w-100">
                        <header></header>
                        <div class="col-sm-12">
                            <div class="product clearfix" *ngFor="let product of products">
                                <div class="d-flex flex-row bd-highlight mb-2 align-content-center">
                                    <div class="p-2 bd-highlight align-self-center index">
                                        <i class="loro-icon icon-lot-{{product.position}}"></i>
                                    </div>
                                    <div class="align-self-center">
                                        <div class="pl-2 align-self-center description">{{product.product.description}}</div>
                                        <div class="pl-2 pb-2 pr-2 bd-highlight align-self-center name">{{product.product.name}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-8 h-100"></div>
    </div>
    <div id="cyclist-wrap" class="cyclist-wrap" style="width:100%; height: 100%;"></div>
</div>