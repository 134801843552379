<div class="d-flex align-items-center justify-content-center h-100">
    <div class="h-100 d-table"></div>
    <div class="d-table-cell align-middle centered-content">
        <header>
            <img src="./../assets/images/logo-loro.svg" class="logo img-fluid">
            <br>
            <img src="./../assets/images/banner-promo.png" class="img-fluid" style="width:75%;">
        </header>
        <ul class="list-inline">
            <li class="list-inline-item">
                <a (click)="selectedDevice('tablet')" class="loro-btn-orange loro-btn-big loro-btn-shadow-left">
                    <i class="loro-icon icon-tablette"></i> <span class="loro-btn-label">Tablette</span>
                </a>
            </li>
            <li class="list-inline-item">
                <a (click)="selectedDevice('tv')" class="loro-btn-orange loro-btn-big loro-btn-shadow-bottom">
                    <i class="loro-icon icon-ecran-ext"></i> <span class="loro-btn-label">écran extérieur</span>
                </a>
            </li>
        </ul>
    </div>
</div>

<div id="top-nav" class="d-flex justify-content-between  align-items-center bd-highlight mb-0">
    <div class="p-3">
        <a (click)="logout()" class="loro-btn-small loro-btn-grey btn-logout">
            <i class="loro-icon icon-logout" aria-hidden="true"></i>
        </a>
    </div>
</div>
