<div id="{{ gameSlug }}" class="d-flex justify-content-between  align-items-center bd-highlight mb-0 h-100">
    <div id="page-content" class="centered-content loro-content-530">
        <div class="box d-flex">
            <!-- TODO: delete this div after April 29, 2018 -->
            <div *ngIf="'cyclist' ==  gameSlug" style="font-size: 36px;color: #fff;font-weight: 900;position: absolute;left: 0;right: 0;top: 0;">
                CONCOURS TOUR DE ROMANDIE
            </div>



            <form (ngSubmit)="onSubmit(inscriptionForm)" #inscriptionForm="ngForm" [hidden]="submitted" [formGroup]="registerForm">
                <div class="title">Inscriptions</div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="firstName">Prénom *</label>
                            <input formControlName="firstName" type="text" class="form-control" id="firstName" name="firstName" autocomplete="first-name" required>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="lastName">Nom *</label>
                            <input formControlName="lastName" type="text" class="form-control" id="lastName" name="lastName" autocomplete="last-name" required>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-9">
                        <div class="form-group">
                            <label for="street">Rue *</label>
                            <input type="text" formControlName="street" class="form-control" id="street" name="street" autocomplete="street" required>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="streetNumber">N°</label>
                            <input type="text" formControlName="streetNumber" class="form-control" id="streetNumber" name="streetNumber" autocomplete="street-number">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <label for="postalCode">NPA *</label>
                            <input type="number" formControlName="postalCode" class="form-control" id="postalCode" name="postalCode" autocomplete="postal-code" required>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="form-group">
                            <label for="locality">Localité *</label>
                            <input type="text" formControlName="locality" class="form-control" id="locality" name="locality" autocomplete="locality" required>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-5">
                        <div class="form-group">
                            <label for="email">E-mail</label>
                            <input type="email" formControlName="email" class="form-control" id="email" name="email" autocomplete="email" required>
                        </div>
                    </div>

                    <div class="col-7">
                        <label>Date de naissance *</label>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <select formControlName="day" name="day" id="day" class="form-control" required>
                                        <option value="">Jour...</option>
                                        <option *ngFor="let day of selectDays" [ngValue]="day.value"
                                                [selected]="day.value == null">{{day.text}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <select formControlName="month" name="month" id="month" class="form-control" required>
                                        <option value="">Mois...</option>
                                        <option *ngFor="let month of selectMonths" [ngValue]="month.value"
                                                [selected]="month.value == null">
                                            {{month.text}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <select formControlName="year" name="year" id="year" class="form-control" required>
                                        <option value="">Année...</option>
                                        <option *ngFor="let year of selectYears" [ngValue]="year.value"
                                                [selected]="year.value == null">{{year.text}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <p style="font-size: 13px;color: #ffa04d;">Aucune inscription à la newsletter, vous recevrez uniquement un e-mail de confirmation au concours. Conditions de participation sous
                    <a href="https://www.loro.ch" target="_blank" style="color: #ffa04d;">www.loro.ch</a>
                </p>







                <!--<div class="row">
                    <div class="col-10 offset-md-1">
                        <label>Date de naissance *</label>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <select [(ngModel)]="selectedDay" name="day" id="day" class="form-control" required
                                            #name="ngModel">
                                        <option *ngFor="let day of selectDays" [ngValue]="day.value"
                                                [selected]="day.value == null">{{day.text}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <select [(ngModel)]="selectedMonth" name="month" id="month" class="form-control"
                                            required #name="ngModel">
                                        <option *ngFor="let month of selectMonths" [ngValue]="month.value"
                                                [selected]="month.value == null">
                                            {{month.text}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <select [(ngModel)]="selectedYear" name="year" id="year" class="form-control"
                                            required #name="ngModel">
                                        <option *ngFor="let year of selectYears" [ngValue]="year.value"
                                                [selected]="year.value == null">{{year.text}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->

               <!-- <div class="row">
                    <div class="col-4  offset-md-4">
                        <div class="form-group">
                            <label for="postalCode">NPA *</label>
                            <input type="number" maxlength="4" minlength="4" class="form-control"
                                   [(ngModel)]="model.postalCode" pattern="[0-9]{4}" id="postalCode" name="postalCode"
                                   required #name="ngModel" autocomplete="postal-code">
                        </div>
                    </div>
                </div>-->

                <!--<div class="row">
                    <div class="col-10 offset-md-1">
                        <div class="form-group">
                            <label for="emailIsLoroUserName">Disposez-vous d'un compte LoRo ?</label>
                            <div class="switch">
                                <input type="checkbox" [(ngModel)]="model.emailIsLoroUserName" data-ng-value="true"
                                       id="emailIsLoroUserName" name="emailIsLoroUserName"
                                       class="cmn-toggle cmn-toggle-yes-no">
                                <label for="emailIsLoroUserName" data-on="Oui" data-off="Non"></label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="emailIsLoroUserName">E-mail *</label>
                            <input type="email" class="form-control" [(ngModel)]="model.email" id="email" name="email"
                                   pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required autocomplete="email">
                        </div>
                    </div>
                </div>-->

               <!-- <div class="form-group">
                    <label for="optIn">Voulez-vous reçevoir notre newsletter ?</label>
                    <div class="switch">
                        <input type="checkbox" [(ngModel)]="model.optIn" data-ng-value="true" id="optIn" name="optIn"
                               class="cmn-toggle cmn-toggle-yes-no">
                        <label for="optIn" data-on="Oui" data-off="Non"></label>
                    </div>
                </div>
-->

                <!--<button type="submit" class="loro-btn-orange loro-btn-medium loro-btn-shadow-right"
                        [disabled]="!inscriptionForm.form.valid">
                    <i class="loro-icon icon-fleche-droite"></i>
                </button>

                <button type="button" class="loro-btn-grey loro-btn-small loro-btn-shadow-right"
                        (click)="skipRegistration()" [disabled]="inscriptionForm.form.valid">
                    <i class="loro-icon icon-ignore-etape"></i>
                </button>
-->

                <button type="submit" class="loro-btn-orange loro-btn-medium loro-btn-shadow-right"
                        [disabled]="!registerForm.valid">
                    <i class="loro-icon icon-fleche-droite"></i>
                </button>
            </form>
        </div>
    </div>
</div>

<div id="top-nav" class="d-flex justify-content-between  align-items-center bd-highlight mb-0">
    <div class="p-3">
        <a [routerLink]="['/game/choose']" class="loro-btn-small loro-btn-grey btn-logout">
            <i class="loro-icon icon-fleche-gauche" aria-hidden="true"></i>
        </a>
    </div>
    <div class="p-0">
        <div id="circle-nav-wrapper">
            <button type="button" class="collapsed navbar-toggle trigger">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <ul id="circle-nav">
                <li>
                    <a [routerLink]="['/game/choose']">
                        <i class="loro-icon icon-menu-jeu"></i>
                    </a>
                </li>
                <li>
                    <a href="#" disabled="disabled">
                        <i class="loro-icon icon-demo"></i>
                    </a>
                </li>
                <li>
                    <a href="#" disabled="disabled">
                        <i class="loro-icon icon-nouveau-joueur"></i>
                    </a>
                </li>
                <li>
                    <a [routerLink]="['/dashboard']">
                        <i class="loro-icon icon-reglage"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div id="bottom-logos" class="d-flex justify-content-between align-items-center bd-highlight mb-0">
    <div class="p-3">
		<!--
        <img class="img-fluid" src="./../../../assets/images/icons/icon-forbidden-18.svg">
		-->
    </div>
    <div class="p-3">
        <img class="img-fluid" src="./../../../assets/images/logo-loro.svg">
    </div>
</div>
