<div id="circle-nav-wrapper">
    <button type="button" class="collapsed navbar-toggle trigger">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
    </button>
    <ul id="circle-nav">
        <li>
            <a [routerLink]="['/game/choose']">
                <i class="loro-icon icon-menu-jeu"></i>
            </a>
        </li>
        <li>
            <a disabled="disabled">
                <i class="loro-icon icon-demo"></i>
            </a>
        </li>
        <li>
            <a [routerLink]="['/game/register/cyclist']">
                <i class="loro-icon icon-nouveau-joueur"></i>
            </a>
        </li>
        <li>
            <a [routerLink]="['/dashboard']">
                <i class="loro-icon icon-reglage"></i>
            </a>
        </li>
    </ul>
</div>

<div class="container-fluid h-100" *ngIf="arrivalPositions[0]">
    <div class="row h-100">
        <div class="col-3 col-md-4 h-100">
            <section id="positions-wrapper" class="d-flex justify-content-center h-100">
                <div class="w-100 h-100 d-table">
                    <div id="positions" class="d-table-cell w-100">
                        <header></header>
                        <div class="col-sm-12">
                            <div class="position clearfix"
                                 *ngFor="let ap of arrivalPositions; let rank = index; let fst = first;">
                                <div class="d-flex flex-row bd-highlight mb-2 align-content-center">
                                    <div class="rank">{{rank + 1}} <sup *ngIf="fst">ER</sup><sup *ngIf="!fst">E</sup>
                                    </div>
                                    <div class="p-2 bd-highlight align-self-center riderIndex">
                                        <img src="./../assets/images/games/cyclist/tags/marque-classement-{{ap.riderIndex}}.png" alt="">
                                    </div>
                                    <div class="p-2 bd-highlight align-self-center name">{{ap.riderName}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-8 h-100">
            <div class="col-sm-12 h-100 d-table">
                <div class="d-table-cell align-middle game-container">
                    <div id="game-result" class="d-table">
                        <div class="winning-rider winning-rider-{{arrivalPositions[0].riderIndex}}"
                             id="winning-rider-{{arrivalPositions[0].riderIndex}}"></div>
                        <img class="img-fluid bravo loro-w-800" src="./../assets/images/games/cyclist/bravo.png"
                             style="margin-top: -60px;">

                        <div class="game-result-inner">
                            <div class="row">
                                <div class="col-12">
                                    <div class="winner winner-8 clearfix">
                                        <div class="d-flex flex-row bd-highlight mb-0 align-content-center">
                                            <div class="rank">1<sup>ER</sup></div>
                                            <div class="bd-highlight align-self-center riderName">
                                                <img src="./../assets/images/games/cyclist/tags/marque-classement-{{arrivalPositions[0].riderIndex}}.png"
                                                     alt="">
                                            </div>
                                            <div class="p-2 bd-highlight align-self-center name">
                                                {{arrivalPositions[0].riderName}}
                                            </div>
                                        </div>
                                    </div>
                                    <h1>Vous avez gagné</h1>
                                    <div class="winner-product">
                                        {{products[0].product.name}}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="winner winner-8 clearfix">
                                        <div class="d-flex flex-row bd-highlight mb-0 align-content-center">
                                            <div class="rank">2<sup>E</sup></div>
                                            <div class="bd-highlight align-self-center riderName">
                                                <img src="./../assets/images/games/cyclist/tags/marque-classement-{{arrivalPositions[1].riderIndex}}.png"
                                                     alt="">
                                            </div>
                                            <div class="p-2 bd-highlight align-self-center name">
                                                {{arrivalPositions[1].riderName}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="winner-product winner-product-small">
                                        {{products[1].product.name}}
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="winner winner-8 clearfix">
                                        <div class="d-flex flex-row bd-highlight mb-0 align-content-center">
                                            <div class="rank">3<sup>E</sup></div>
                                            <div class="bd-highlight align-self-center riderName">
                                                <img src="./../assets/images/games/cyclist/tags/marque-classement-{{arrivalPositions[2].riderIndex}}.png"
                                                     alt="">
                                            </div>
                                            <div class="p-2 bd-highlight align-self-center name">
                                                {{arrivalPositions[2].riderName}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="winner-product winner-product-small">
                                        {{products[2].product.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>