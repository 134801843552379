<div class="d-flex align-items-center justify-content-center h-100">
    <div class="h-100 d-table"></div>
    <div class="d-table-cell align-middle centered-content">
        <header>
            <img src="./../assets/images/logo-loro.svg" class="logo img-fluid">
            <br>
            <img src="./../assets/images/banner-promo.png" class="img-fluid" style="width:75%;">
        </header>
        <ul class="list-inline">
            <li class="list-inline-item" *ngIf="device == 'tv'">
                <button [routerLink]="['/screensaver']" [disabled]="!(hasMessages)"
                        class="loro-btn-orange loro-btn-big loro-btn-shadow-left">
                    <i class="loro-icon icon-veille"></i> <span class="loro-btn-label">Veille</span>
                </button>
            </li>
            <li class="list-inline-item">
                <a [routerLink]="['/game/choose']" class="loro-btn-orange loro-btn-big loro-btn-shadow-bottom">
                    <i class="loro-icon icon-jeu"></i> <span class="loro-btn-label">Jeu</span>
                </a>
            </li>
            <li class="list-inline-item">
                <a [routerLink]="['/register']" class="loro-btn-orange loro-btn-big loro-btn-shadow-bottom">
                    <i class="loro-icon icon-formulaire"></i> <span class="loro-btn-label">Inscriptions libres</span>
                </a>
            </li>
            <li class="list-inline-item">
                <a [routerLink]="['/settings']" class="loro-btn-grey loro-btn-big loro-btn-shadow-right">
                    <i class="loro-icon icon-reglage"></i> <span class="loro-btn-label">Réglages</span>
                </a>
            </li>
        </ul>
    </div>
</div>

<div id="top-nav" class="d-flex justify-content-between  align-items-center bd-highlight mb-0">
    <div class="p-3">
        <a [routerLink]="['/device']" class="loro-btn-small loro-btn-grey btn-logout">
            <i class="loro-icon icon-fleche-gauche" aria-hidden="true"></i>
        </a>
    </div>
</div>
