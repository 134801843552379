<div id="circle-nav-wrapper">
    <button type="button" class="collapsed navbar-toggle trigger">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
    </button>
    <ul id="circle-nav">
        <li>
            <a [routerLink]="['/game/choose']">
                <i class="loro-icon icon-menu-jeu"></i>
            </a>
        </li>
        <li [ngSwitch]="isDemoMode">
            <a *ngSwitchCase="true" href="#" disabled="disabled">
                <i class="loro-icon icon-demo"></i>
            </a>
            <a *ngSwitchDefault [routerLink]="['/game/play/'+inscriptionId+'/tribolo/demo']">
                <i class="loro-icon icon-demo"></i>
            </a>
        </li>
        <li>
            <a [routerLink]="['/game/register/tribolo']">
                <i class="loro-icon icon-nouveau-joueur"></i>
            </a>
        </li>
        <li>
            <a [routerLink]="['/dashboard']">
                <i class="loro-icon icon-reglage"></i>
            </a>
        </li>
    </ul>
</div>

<div class="container-fluid h-100">
    <div class="row h-100">
        <div class="col-3 col-md-4 h-100">
            <section id="products-wrapper" class="d-flex justify-content-center h-100">
                <div class="w-100 h-100 d-table">
                    <div id="products" class="d-table-cell w-100">
                        <header [ngClass]="{'demo-mode': isDemoMode}"></header>
                        <div class="col-sm-12">
                            <div [hidden]="isDemoMode" class="product clearfix" *ngFor="let product of products">
                                <div class="d-flex flex-row bd-highlight mb-2 align-content-center">
                                    <div class="p-2 bd-highlight align-self-center index">
                                        <i class="loro-icon icon-lot-{{product.position}}"></i>
                                    </div>
                                    <div class="align-self-center">
                                        <div class="pl-2 align-self-center description">{{product.product.description}}</div>
                                        <div class="pl-2 pb-2 pr-2 bd-highlight align-self-center name">{{product.product.name}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="col-8 h-100">
            <div class="d-table h-100 w-100">
                <div class="d-table-cell align-middle game-container">
                    <div class="d-table" style="margin: 0 auto;">
                        <div id="tribolo-game" class="tribolo-game" style="width:100%; height: 100%;"></div>
                    </div>
                    <div id="game-result" class="d-table">
                        <div class="d-table-cell align-middle">
                            <div [ngSwitch]="isDemoMode">
                                <div *ngSwitchCase="false">
                                    <div [ngSwitch]="hasWon">
                                        <div *ngSwitchCase="true">
                                            <img src="./../../../assets/images/games/banner-you-win.png"
                                                 class="loro-w-700">
                                        </div>
                                        <div *ngSwitchDefault>
                                            <img src="./../../../assets/images/games/banner-you-lost.svg" class="w-100"
                                                 style="padding:0 85px">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="content-middle-wrapper">
                                <div [ngSwitch]="isDemoMode">
                                    <div *ngSwitchCase="true" class="demo-mode">
                                        <div class="result demo-result">
                                            <h1>Merci de<br>votre<br>participation</h1>
                                        </div>
                                        <div class="replay demo-replay" style="display:none;">
                                            <h2><a [routerLink]="['/game/play/'+inscriptionId+'/tribolo']">Jouez !</a>
                                            </h2>
                                        </div>
                                    </div>
                                    <div *ngSwitchDefault class="play-mode">
                                        <div class="result">
                                            <div [ngSwitch]="hasWon">
                                                <div *ngSwitchCase="true">
                                                    <h1>VOUS AVEZ<br>GAGNÉ</h1>
                                                    <h2>{{ wonProduct }}</h2>
                                                </div>
                                                <div *ngSwitchDefault>
                                                    <h1>Vous n'avez <br>pas gagné</h1>
                                                    <h2>Merci de votre participation</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>