<div id="page-content" class="d-flex align-items-center justify-content-center h-100">
    <div class="centered-content loro-content-640">
        <div class="row">
            <div class="col-6 game-box game-fakir">
                <a [routerLink]="['/game/register', 'fakir']"></a>
            </div>
            <div class="col-6 game-box game-magot">
                <a [routerLink]="['/game/register', 'magot']"></a>
            </div>
            <div class="col-6 game-box game-tribolo">
                <a [routerLink]="['/game/register', 'tribolo']"></a>
            </div>
            <div class="col-6 game-box game-luckywheel">
                <a [routerLink]="['/game/register', 'luckywheel']"></a>
            </div>
            <div class="col-6 game-box game-pmu">
                <a [routerLink]="['/game/register', 'pmu']"></a>
            </div>
            <div class="col-6 game-box game-loto-express">
                <a [routerLink]="['/game/register', 'loto-express']"></a>
            </div>
            <div class="col-6 game-box game-cyclist">
                <a [routerLink]="['/game/register', 'cyclist']"></a>
            </div>
            <div class="col-6 game-box game-football">
                <a [routerLink]="['/game/register', 'football']"></a>
            </div>
        </div>
    </div>

    <div id="bottom-logos" class="d-flex justify-content-between align-items-center bd-highlight mb-0">
        <div class="p-3">
			<!--
            <img class="img-fluid" src="./../../../assets/images/icons/icon-forbidden-18.svg">
			-->
        </div>
        <div class="p-3">
            <img class="img-fluid" src="./../../../assets/images/logo-loro.svg">
        </div>
    </div>

    <div id="top-nav" class="d-flex justify-content-between  align-items-center bd-highlight mb-0">
        <div class="p-3">
            <a (click)="logout()" class="loro-btn-small loro-btn-grey btn-logout">
                <i class="loro-icon icon-logout" aria-hidden="true"></i>
            </a>
        </div>
        <div class="p-0">
            <div id="circle-nav-wrapper">
                <button type="button" class="collapsed navbar-toggle trigger">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <ul id="circle-nav">
                    <li>
                        <a [routerLink]="['/game/choose']">
                            <i class="loro-icon icon-menu-jeu"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" disabled="disabled">
                            <i class="loro-icon icon-demo"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" disabled="disabled">
                            <i class="loro-icon icon-nouveau-joueur"></i>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/dashboard']">
                            <i class="loro-icon icon-reglage"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="choose-content">
    <span>Choisissez votre jeu</span>
</div>