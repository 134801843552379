<div id="page-content" class="h-100">
    <div class="d-flex align-items-start justify-content-center ">
        <div class="centered-content">
            <div class="d-flex mb-3 justify-content-center" style="height: 102px">
                <div class="d-flex align-items-center justify-content-center">
                    <h2 *ngIf="model">{{ message.title }}</h2>
                </div>
            </div>
        </div>
    </div>

    <div class="centered-content">
        <div class="row">
            <div class="col-12 game-box">
                <div *ngIf="model">
                    <form (ngSubmit)="onSubmit(messageForm)" #messageForm="ngForm">
                        <div class="form-group">
                            <label for="published">Publier le message</label>
                            <div class="switch">
                                <input type="checkbox" [(ngModel)]="model.published" data-ng-value="true" id="published"
                                       name="published" class="cmn-toggle cmn-toggle-yes-no">
                                <label for="published" data-on="Oui" data-off="Non"></label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-8 offset-md-2">
                                <div class="form-group">
                                    <label for="message">Message *</label>
                                    <textarea class="form-control" [(ngModel)]="model.message" id="message"
                                              name="message" required #name="ngModel" rows="10"
                                              style="min-height:100px;"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8 offset-md-2">
                                <div class="form-group">
                                    <label for="messageSecondary">Message 2</label>
                                    <textarea type="text" class="form-control" [(ngModel)]="model.messageSecondary"
                                              id="messageSecondary" name="messageSecondary" #name="ngModel" rows="10"
                                              style="min-height:100px;"></textarea>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary" [disabled]="!messageForm.form.valid">
                            Enregistrer
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="top-nav" class="d-flex justify-content-between  align-items-center bd-highlight mb-0">
    <div class="p-3">
        <a [routerLink]="['/settings/messages']" class="loro-btn-small loro-btn-grey btn-logout">
            <i class="loro-icon icon-fleche-gauche" aria-hidden="true"></i>
        </a>
    </div>
</div>



