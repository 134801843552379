<div id="page-content" class="h-100">
    <div class="d-flex align-items-start justify-content-center">
        <div class="centered-content">
            <div class="d-flex mb-3 justify-content-center" style="height: 102px">
                <div class="d-flex align-items-center justify-content-center">
                    <h2>Options</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="centered-content">
        <div class="row">
            <div *ngIf="device == 'tv'" class="col-12 game-box">
                <p>Effacer, modifier, synchroniser les messages de ce support.</p>
                <p><a [routerLink]="['/settings/messages']" class="btn btn-primary">Gérer les écrans de veille</a></p>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-12 game-box">
                <p>Synchroniser le fond d'écran des inscriptions libres.</p>
                <p><a [routerLink]="['/settings/inscription-config']" class="btn btn-primary">Gérer les inscriptions libres</a></p>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-12 game-box">
                <p>Statistiques de jeux pour la journée.</p>
                <p><a [routerLink]="['/settings/stats']" class="btn btn-primary">Afficher les statistiques</a></p>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-12 game-box">
                <p>Envoyer les inscriptions de ce support <strong>non synchronisées</strong> vers le serveur.</p>
                <p>
                    <button (click)="pushInscriptions()" [disabled]="!(isConnected$ | async)" class="btn btn-primary">
                        Envoyer les inscriptions vers le serveur ({{ unsycInscriptionsCount$ | async }})
                    </button>
                </p>
                <hr>
            </div>
        </div>
        <!--
        <div class="row">
          <div class="col-12 game-box">
            <p>Envoyer les inscriptions de la tablette non synchronisées vers le serveur.</p>

          </div>
        </div>
        -->

        <div class="row">
            <div class="col-12 game-box">
                <p>Réinitialiser tous les réglages des jeux</p>
                <p><a (click)="clearLocalData()" class="btn btn-primary">Synchroniser avec le serveur</a></p>
            </div>
        </div>

    </div>
</div>

<div id="top-nav" class="d-flex justify-content-between  align-items-center bd-highlight mb-0">
    <div class="p-3">
        <a [routerLink]="['/dashboard']" class="loro-btn-small loro-btn-grey btn-logout">
            <i class="loro-icon icon-fleche-gauche" aria-hidden="true"></i>
        </a>
    </div>
</div>